<template>
    <div>
        <!-- Start Header Area -->
        <Header>
            <img slot="logo" src="@/assets/images/logo/logo.png" />
        </Header>
        <!-- End Header Area -->
        <!-- Start Contact Area  -->
        <div class="rn-contact-area rn-section-gap bg_color--1 mt--100">
            <div class="contact-form--1">
                    <Contact class="class-z-index">
                        <img slot="contact-img"
                             src="@/assets/images/about/about-6.jpg"
                             alt="elin" />
                    </Contact>
            </div>
        </div>
        <!-- End Contact Area  -->
        <!-- Start Contact Address Area 
        <div class="rn-contact-address-area rn-section-gap bg_color--5">  -->
        <div class="rn-contact-address-area bg_color--5">
            <v-container>
                <ContactAddress />
            </v-container>
        </div>
        <!-- End Contact Address Area  -->

        <Footer />
    </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import ContactAddress from "@/components/contact/ContactAddress";
  import Contact from "./Contact1";
  import Footer from "@/components/footer/FooterTwo";
  import MarkerClusterer from '@google/markerclusterer';

  export default {
    components: { Header, ContactAddress, Contact, Footer },
    data() {
      return {};
    },
  };
</script>

<style>
    .class-z-index {
        z-index: 0;
    }
</style>
